.accent {
  font-weight: 700;
  color: #c00000;
}

@media (min-width: 1200px) {
  .container, .container-xl, .container-lg, .container-md, .container-sm {
      max-width: 1190px;
  }
}

@media (min-width: 1400px) {
  .container, .container-xxl, .container-xl, .container-lg, .container-md, .container-sm {
      max-width: 1390px;
  }
}

@media (min-width: 900px) {
  .modal-xl {
      max-width: 95%;
  }
}

@media (min-width: 1660px) {
  .modal-xl {
      max-width: 90%;
  }
}

.user {
  margin: 0 0 1.5rem;
  padding: 0.8rem;
  text-align: right;
}

.student-navbar {
  position: static;
  padding: 0.5rem 0;
}

.student-navbar > .container-md {
  display: block;
}

.breadcrumb-item+.breadcrumb-item:before {
  color: #ffffff;
}

.card-list .list-link:before {
  border-right: 2px solid #c00000;
  bottom: 0;
  content: "";
  display: none;
  left: -2rem;
  position: absolute;
  top: 0;
}

.card-list .active:before {
  display: block;
} 

.card-list .active {
 color: #161c2d!important;
}

#navbarCollapse .navbar-nav .nav-link {
  text-decoration: underline 0.15em rgba(255, 255, 255, 0);
  transition: text-decoration-color 300ms;
}

#navbarCollapse .navbar-nav .nav-link:hover {
  text-decoration-color: rgb(255, 255, 255);
}

.navbar-dark .navbar-nav .nav-link.active, .navbar-dark .navbar-nav .show>.nav-link {
  font-weight: 800;
}

.break {
  border-top: 2px solid var(--bs-primary-desat);
}

.alert-primary-soft{
  background-color: rgba(192,0,0,0.1);
  color: #c00000;
}

.alert-secondary-soft{
  background-color: rgba(80,102,144,0.1);
  color: #506690;
}

.alert-success-soft{
  background-color:rgba(66,186,150,0.1);
  color:#42BA96;
}

.alert-info-soft{
  background-color:rgba(75,167,255,0.1);
  color:#4BA7FF;
}

.alert-warning-soft{
  background-color:rgba(248,157,27,0.1);
  color:#F89D1B;
}

.alert-danger-soft{
  background-color:rgba(237,28,37,0.1);
  color:#ED1C25;
}

.alert-icon {
  padding: 0.95rem;
  background: hsla(0, 0%, 100%, .1);
}

.alert-message {
  padding: 0.95rem;
  width: 100%;
  box-sizing: border-box;
}

.alert {
  padding: 0;
  display: flex;
  color: #212529;
}

td div .form-control, td div .btn{
  padding: .25rem .7rem;
  font-size: inherit;
}

.form-control {
  border-color: var(--bs-gray-300);
}

.logo-srce{
  height: 50px;
}

.btn-close:focus {
  background:transparent url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23C00000'%3E%3Cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3E%3C/svg%3E") 50%/.7em auto no-repeat;
}

select,
select option {
  color: #000000;
}

select:invalid,
select option[value=""] {
  color: #999999;
  border-color: #d9e2ef;
}

input[type=date]:invalid {
  color: #999999;
}

.form-control::placeholder {
  color: #999999;
}

.form-switch .form-check-input:checked {
  background-color: #42BA96;
  border-color: #42BA96;
}

.date-filter-label {
  font-size: 12px;
}

.date-filter-input {
  border: none;
  border-bottom: 1px solid #8a8a8a;
  padding-bottom: 2px;
  width: 100%;
}

.date-filter-input[type="date"]:invalid {
  color: #727272;
}

.date-filter-input:hover {
  padding-bottom: 1px;
  border-bottom: 2px solid black;
}

.date-filter-input:focus {
  outline: none;
  padding-bottom: 1px;
  border-bottom: 2px solid #c00000;
}
.bg-blue {
  background-color:#003da6!important
}

.bg-student {
  background-color:#92338a!important
}

.bg-admin{
  background-color:#cb9700!important
}

.bg-research {
  background-color: #6bca98!important
}
